.HeaderCon1 {
  background: var(--app-green, #3a7d44);
  box-sizing: border-box;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.HeaderCon2ForColorChangeWhenMiniScreenOnDisplay {
  background: #306437;
}

.HeaderCon1BolckAction {
  background: #00000073;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.HeaderCon2 {
  box-sizing: border-box;
  flex-direction: column;
  flex: 1 0 0;
  align-items: flex-start;
  display: flex;
}

.HeaderCon3 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 6px 12px;
  display: flex;
}

.HeaderCon4, .HeaderCon5 {
  align-items: flex-end;
  display: flex;
}

.HeaderCon6 {
  align-items: center;
  gap: 6px;
  display: flex;
}

.MainAppConWithNavigationCon2 {
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.MainAppConWithNavigationCon {
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.MainAppConWithNavigationContentCon {
  background: var(--app-beige);
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden scroll;
}

.MainAppConWithNavigationContentCon > * {
  scroll-snap-align: start;
}

.HomeScreenCon1 {
  background: var(--app-white);
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden scroll;
}

.HomeScreenConPaddingBottome {
  width: 100vw;
}

.HomeScreenCon2 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.HomeScreenCon3C {
  background: linear-gradient(#3a7d44 4.5%, #3a7d441a 100%);
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-top: 6px;
  display: flex;
  position: relative;
}

#HomeScreenContenConForSeachAndAddReloadMessage {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  top: -20px;
}

.HomeScreenCon3B {
  background: var(--app-white);
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.HomeScreenCon3 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 0 16px;
  display: flex;
}

.HomeScreenConAddCon1 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 0 12px;
  display: flex;
}

.HomeScreenConAddCon2 {
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.HomeScreenConAddCon3 {
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.HomeScreenCon4 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  padding: 24px 0 88px 12px;
  display: flex;
}

.AppNameMain {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.AppNameSecondary {
  color: var(--app-gold-yellow, #f4c542);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.AppNameContry {
  color: var(--app-gray-soft, #e0e0e0);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}

.HeaderUserHola {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.HeaderUserName {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.iluUploadButton {
  transition: transform 1s ease-in-out;
  display: none;
}

.iluUploadStart {
  transform: translateY(80px);
}

.iluUploadMidle {
  transition: transform .1s ease-in-out;
  transform: translateY(0);
}

.iluUploadUp {
  transform: translateY(-80px);
}
/*# sourceMappingURL=index.a00a6d81.css.map */
