.HeaderCon1 {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  background: var(--app-green, #3a7d44);
  box-sizing: border-box;
  position: relative;
}
.HeaderCon2ForColorChangeWhenMiniScreenOnDisplay {
  background: #306437;
}
.HeaderCon1BolckAction {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.45);
}
.HeaderCon2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 10px; */
  flex: 1 0 0;
  box-sizing: border-box;
}
.HeaderCon3 {
  display: flex;
  padding: 6px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.HeaderCon4 {
  display: flex;
  align-items: flex-end;
  /* gap: 6px; */
}
.HeaderCon5 {
  display: flex;
  align-items: flex-end;
}
.HeaderCon6 {
  display: flex;
  align-items: center;
  gap: 6px;
}

.MainAppConWithNavigationCon2 {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  /* flex: 1 0 0; */
  /* align-self: stretch; */
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
.MainAppConWithNavigationCon {
  /* flex: 1 0 0; */
  /* align-self: stretch; */
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex; /* Flexbox layout */
  flex-direction: column; /* Stack children vertically */
  align-items: center;
  /* overflow-y: scroll; */
}

.MainAppConWithNavigationContentCon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  /* padding-top: 130px; */
  /*   Enable vertical scrolling */
  /* padding-bottom: 82px; */
  background: var(--app-beige);
  box-sizing: border-box;

  /* scroll-snap-type: y mandatory; */
  /* Enable scroll snapping on the y-axis disabled becuase of conflicting loading proccess. enabled in the js */
}

.MainAppConWithNavigationContentCon > * {
  scroll-snap-align: start; /* Adjust alignment as needed (start, center, or end) */
}

.HomeScreenCon1 {
  position: absolute; /* Inherits layering behavior */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  display: flex; /* Flexbox layout */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  gap: 24px; /* Space between children */
  overflow: hidden; /* Prevent horizontal overflow */
  overflow-y: scroll; /* Enable vertical scrolling */
  background: var(--app-white);
}
.HomeScreenConPaddingBottome {
  width: 100vw;
  /* height: 88px; */
}
.HomeScreenCon2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.HomeScreenCon3C {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding-top: 6px;
  align-self: stretch;
  height: 100%;
  width: 100%;
  margin: 0 auto;

  background: linear-gradient(
    180deg,
    #3a7d44 4.5%,
    rgba(58, 125, 68, 0.1) 100%
  );

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
#HomeScreenContenConForSeachAndAddReloadMessage {
  position: absolute;
  top: -20;

  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 100% */
}
.HomeScreenCon3B {
  /* max-width: 500px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  /* min-height: 200px; */
  margin: 0 auto;

  background: var(--app-white);
}
.HomeScreenCon3 {
  /* max-width: 500px; */
  display: flex;
  padding: 0px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 12;

  /* margin: 0 auto; */
}
.HomeScreenConAddCon1 {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.HomeScreenConAddCon2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.HomeScreenConAddCon3 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}
.HomeScreenCon4 {
  display: flex;
  padding: 24px 0px 24px 12px;
  padding-bottom: 88px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
/* .HomeScreenCon5 {
  max-width: 500px;
  display: flex;
  padding: 0px 18px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
} */
.AppNameMain {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 100% */
}
.AppNameSecondary {
  color: var(--app-gold-yellow, #f4c542);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
}
.AppNameContry {
  color: var(--app-gray-soft, #e0e0e0);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
}
.HeaderUserHola {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.HeaderUserName {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
/* .iluUploadButton {
  display: none;
  transition: transform 1s ease-in-out;
  transform: translateY(40px);
}

.iluUploadMidle {
  transform: translateY(0px);
}

.iluUploadUp {
  transform: translateY(-40px);
} */

.iluUploadButton {
  display: none;
  transition: transform 1s ease-in-out;
}

.iluUploadStart {
  transform: translateY(80px); /* Starts below */
}

.iluUploadMidle {
  transition: transform 0.1s ease-in-out;
  transform: translateY(0px); /* Moves to center */
}

.iluUploadUp {
  transform: translateY(-80px); /* Moves up */
}
